<template>
  <span class="donation-feedback">
    <slot></slot>
  </span>
</template>

<script>
  import { gsap } from 'gsap';
  import SplitText from '../utils/split_text';

  export default {
    props: {
      feedback: {
        type: Array,
        default: () => [],
      },
      selector: {
        type: String,
        default: '',
      },
      placeholder: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        amount: 0,
        input: null,
        feedbackText: '',
        letters: [],
        isAnimating: false,
      };
    },
    methods: {
      inputEvent(e) {
        this.amount = e.target.value;
      },
      animateTextOut() {
        const that = this;

        this.isAnimating = true;
        gsap.to(this.letters, {
          x: -20,
          opacity: 0,
          duration: 0.6,
          ease: 'circ.in',
          stagger: {
            amount: 0.3,
          },
          onComplete() {
            that.changeText();
            that.animateTextIn();
          },
        });
      },
      animateTextIn() {
        const that = this;

        gsap.from(this.letters, {
          x: 20,
          opacity: 0,
          duration: 0.7,
          ease: 'circ.out',
          stagger: {
            amount: 0.3,
          },
          onComplete() {
            that.isAnimating = false;
          },
        });
      },
      changeText() {
        this.$el.innerHTML = this.feedbackText;
        this.splitText();
      },
      splitText() {
        this.letters = new SplitText(this.$el).splitWordsThanChars().chars;
      },
    },
    watch: {
      amount(val, oldVal) {
        if (val === '' || Number.isNaN(val)) {
          this.feedbackText = this.placeholder;
          return oldVal;
        }

        for (let i = 0; i < this.feedback.length; i += 1) {
          const row = this.feedback[i];
          if (val <= parseFloat(row.amount)) {
            this.feedbackText = row.text;
            break;
          }
        }

        return val;
      },
      feedbackText() {
        if (!this.isAnimating) {
          this.splitText();
          this.animateTextOut();
        }
      },
    },
    mounted() {
      this.input = document.querySelector(this.selector);
      if (this.input) {
        this.amount = this.input.value || 0;
        this.input.addEventListener('input', this.inputEvent);
      }
    },
  };
</script>{

<style scoped lang="scss">
  /deep/ span {
    display: inline-block;
    vertical-align: top;
  }

  /deep/ span[class*='char'] {
    white-space: pre;
  }
</style>
