var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-circle-text rotate position-relative js-observe" },
    [
      _c("div", { ref: "svgCircle", staticClass: "svg-circle" }, [
        _c(
          "svg",
          {
            attrs: {
              version: "1.1",
              xmlns: "http://www.w3.org/2000/svg",
              "xmlns:xlink": "http://www.w3.org/1999/xlink",
              width: "250px",
              height: "250px",
              viewBox: "0 0 250 250"
            }
          },
          [
            _c("defs", [
              _c("path", {
                attrs: {
                  id: "circle-path",
                  d: "M0,125a125,125 0 1,0 250,0a125,125 0 1,0 -250,0 "
                }
              })
            ]),
            _vm._v(" "),
            _c("text", { staticClass: "h3 ls-2 text-lowercase" }, [
              _c(
                "textPath",
                {
                  staticClass: "fill-primary",
                  attrs: { "xlink:href": "#circle-path" }
                },
                [_vm._v(_vm._s(_vm.text))]
              )
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("img", {
        staticClass: "circle-trees",
        attrs: { src: _vm.icon, alt: "trees" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }