<!-- <icon name="arrow" class="fill-white"></icon> -->
<template>
  <div :class="state" class="bg-image">
    <slot></slot>
  </div>
</template>

<script>
  import { throttle } from 'lodash-es';

  export default {
    props: {
      delay: {
        type: Number,
        default: 200,
      },
    },
    data() {
      return {
        state: 'running',
      };
    },
    methods: {
      scroll() {
        if (window.scrollY > this.$el.offsetHeight) {
          this.state = 'paused';
        } else {
          this.state = 'running';
        }
      },
    },
    mounted() {
      window.addEventListener('scroll', throttle(this.scroll, this.delay));
    },
  };
</script>
<style lang="scss" scoped>
  .bg-image {
    will-change: transform;
    // background-attachment: fixed;
  }

  .bg-image {
    animation: 15s cubic-bezier(0, 0.5, 0.5, 1) 1 alternate paused pan-move;
  }

  .running {
    animation-play-state: running;
  }

  .paused {
    animation-play-state: paused;
  }

  @keyframes pan-move {
    0% {
      transform: scale(1.75);
    }

    100% {
      transform: scale(1);
    }
  }
</style>
