var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isAccepted
    ? _c(
        "div",
        {
          ref: "cookieNotice",
          staticClass:
            "l-cookie-notice px-3 py-2 px-lg-4 py-lg-3 m-sm-2 m-lg-3",
          class: { "d-none": _vm.isAccepted }
        },
        [
          _c(
            "div",
            { staticClass: "cms-text text-14 text-white font-weight-medium" },
            [
              _vm._t("default"),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "js-cookie-close btn-accept btn-none text-uppercase font-weight-bold text-white d-inline-flex align-items-center mt-2",
                  attrs: { type: "button" },
                  on: { click: _vm.acceptCookies }
                },
                [_vm._t("btn-accept")],
                2
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "js-cookie-close btn-close btn-none d-flex align-items-center justify-content-center",
                  attrs: { type: "button" },
                  on: { click: _vm.acceptCookies }
                },
                [_vm._t("btn-close")],
                2
              )
            ],
            2
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }