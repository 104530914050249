<template>
  <a class="c-scroll-down" @click="smoothScroll">
    <slot></slot>
    <icon name="chevron" :class=iconClass></icon>
  </a>
</template>

<script>
  import { gsap } from 'gsap';
  import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
  import icon from './Icon.vue';

  // Without this line, ScrollToPlugin may get dropped by your bundler (tree shaking)...
  gsap.registerPlugin(ScrollToPlugin);

  export default {
    props: {
      iconClass: String,
    },
    methods: {
      smoothScroll(e) {
        const id = e.currentTarget.hash.substr(1);
        const target = document.getElementById(id);

        if (target) {
          e.preventDefault();
          gsap.to(window, 0.75, { scrollTo: target, ease: 'power2.inOut' });
        }
      },
    },
    components: {
      icon,
    },
  };
</script>

<style lang="scss" scoped>
  /*@import "src/scss/abstracts/_functions.scss";
  @import "src/scss/abstracts/_variables.scss";
  @import "src/scss/vendor/bootstrap/_custom.scss";

  .c-scroll-down {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    text-decoration: none !important;
    text-transform: uppercase;
    font-weight: $font-weight-bolder;
    font-size: rem-calc(13px);
    letter-spacing: 1px;
    z-index: $zIndex-2;

    &,
    &:hover {
      color: $white;
      outline: none;
    }

    &:hover {
      opacity: 0.75;
    }

    .icon {
      display: block;
      width: 15px;
      height: 10px;
      margin-left: auto;
      margin-right: auto;
    }
  }*/
</style>

<!------------------------------
@Usage

<scroll-down class="mb-6" href="#test" icon-class="fill-white mt-1">
  {{ 'Ontdek hoe'|t }}
</scroll-down>
------------------------------>
