<template>
  <div class="c-circle-text rotate position-relative js-observe">
    <div class="svg-circle" ref="svgCircle">
      <svg version="1.1"
           xmlns="http://www.w3.org/2000/svg"
           xmlns:xlink="http://www.w3.org/1999/xlink"
           width="250px"
           height="250px"
           viewBox="0 0 250 250">
        <defs>
          <path id="circle-path" d="M0,125a125,125 0 1,0 250,0a125,125 0 1,0 -250,0 "/>
        </defs>
        <text class="h3 ls-2 text-lowercase">
          <textPath class="fill-primary" xlink:href="#circle-path">{{ text }}</textPath>
        </text>
      </svg>
    </div>
    <img class="circle-trees" :src="icon" alt="trees">
  </div>
</template>

<script>
  export default {
    props: {
      text: {
        type: String,
        default: '',
      },
      icon: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        isVisible: false,
        observer: null,
      };
    },
    methods: {
      observerCb(entries) {
        entries.forEach((entry) => {
          this.isVisible = entry.isIntersecting;
        });
      },
      scroll() {
        if (this.isVisible) {
          const offset = window.pageYOffset % 360;
          if (this.$refs.svgCircle instanceof SVGElement) {
            // Transform on SVG element needs to be set via setAttribute
            this.$refs.svgCircle.setAttribute('transform', `rotate(${offset})`);
          } else {
            this.$refs.svgCircle.style.transform = `rotate(${offset}deg)`;
          }
        }
      },
    },
    mounted() {
      this.observer = new IntersectionObserver(this.observerCb, {
        rootMargin: '100px 0px 100px 0px',
      });
      this.observer.observe(this.$el);

      window.addEventListener('scroll', this.scroll);
    },
  };
</script>

<style scoped lang="scss">
  @import 'src/scss/abstracts/variables.scss';

  .c-circle-text {
    $icon-size: 80px;

    display: inline-block;

    svg {
      overflow: visible;
    }

    .text {
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      transform-origin: 0 0;
      font-weight: 500;
    }

    .circle-trees {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      width: $icon-size;
      height: $icon-size;
      z-index: $z-index-1;
    }
  }
</style>
